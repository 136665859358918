import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { prettyDOM } from '@testing-library/react'
import { RootState } from 'app/store'
import { parseAssureEmpPersToDto, parseAssureEmpToDto, parseDtoToAssure, parseDtoToPret, parsePretToDto, parseSimulationOnPricing } from 'helpers/parseEmprunteur'
import { createAssureForSimu, createPretForSimu, deleteAssurEmpruntPretByIds, fetchEmprunteurSimulation, fetchListQuestionnaire, updateAssurEmpruntPretByIds, updateAssuresEmprunt, updatePret } from './emprunteurAPI'
import { createPersonnePhysique, editPersonnePhysique } from 'features/assure/assureAPI'
import { fetchGaranties } from 'features/contrat/contrat'


export interface AssureGarantie {
    assureId: string,
    garanties: {
        garantieId: string,
        pretId: string
    } 
}

export interface AssureI {
  assureInfo: any,
  prets: any[],
}

export type EmprunteurState = {
    listAssure: AssureI[],
    loading: boolean,
    listQuestionnaire: any[],
    prevelementDefault: number,
    tauxComDefault: number,
    tauxComPlusDefault: number
}

const initialState: EmprunteurState = {
    // assuresGaranties: [],
    listAssure: [],
    loading: false,
    listQuestionnaire: [],
    prevelementDefault: 0,
    tauxComDefault: 0,
    tauxComPlusDefault: 0
}

//Action async a faire:
// Editer un pret
// Editer un assure
// Ajouter un pret
// Ajouter un assure


export const getEmprunteurSimulationAction = createAsyncThunk(
  'emprunteur/simulation',
  async (params: {simulationId: string, garantiesObligatoires: any}) => {
    const response = await fetchEmprunteurSimulation(params.simulationId);
    return parseSimulationOnPricing(response, params.garantiesObligatoires) as AssureI[];
    // return response
  }
)

export const getListQuestionnaireAction = createAsyncThunk(
  'emprunteur/questionnaire',
  async (params: {age: number, montantPret: number, produitId: string}) => {
    const response = await fetchListQuestionnaire(params.age, params.montantPret, params.produitId);
    return response
  }
)

export const updateAssureEmpAction = createAsyncThunk(
  'emprunteur/updateAssure',
  async (params: {assure: any, assureId: string, persPhysiqueId: string, adresseId: string}) => {
      try {
        const dataPP = parseAssureEmpPersToDto(params?.assure, params?.persPhysiqueId, params?.adresseId);
        const editedPersonnePhysique = await editPersonnePhysique(dataPP);
        
        const dataAssure = parseAssureEmpToDto(editedPersonnePhysique, params?.assure, params?.assureId);
        const updatedAssure = await updateAssuresEmprunt(dataAssure);
  
        return updatedAssure;
      } catch (error) {
        console.error("An error occurred while updating assure:", error);
        throw error; // Propagate the error upward
      }
    }
)

export const deletePretForAssureAction = createAsyncThunk(
  'emprunteur/deletePret',
  async (params: {assureId: string, pretId: string}) => {
    await deleteAssurEmpruntPretByIds(params);
    return params
  }
)

export const createPretAction = createAsyncThunk(
  'emprunteur/createPret',
  async (params: {simulationId: string, data: any, garanties: any}) => {

    const parsedData = parsePretToDto(params.data);
    const response = await createPretForSimu({simulationId: params?.simulationId, data: parsedData})
    const newPret = parseDtoToPret(response, params.garanties, 0);
    return newPret
  }
)

export const updatePretAction = createAsyncThunk(
  'emprunteur/updatePret',
  async (params: {pretData: any, pretId: string, quotite: number, assureId: string}) => {
    try {
      const dataParsed = parsePretToDto(params.pretData); 
      const res = await updatePret(dataParsed);
      const payload = {
        pretId: params.pretId,
        assureEmpruntId: params.assureId,
        quotite: params.quotite
      };

      await updateAssurEmpruntPretByIds(payload);
      return {pret:res, quotite: params?.quotite}
    } catch (error) {
      console.error("An error occurred while updating assure:", error);
      throw error; // Propagate the error upward
    }
  }
)

export const createAssureAction = createAsyncThunk(
  'emprunteur/createAssure',
  async (params: {data: any, simulationId: string}) => {
    const personnePhysique = parseAssureEmpPersToDto(params?.data);
    const pers  = await createPersonnePhysique(personnePhysique)
    const dataAssure = parseAssureEmpToDto(pers, params?.data);
    const res = await createAssureForSimu({simulationId: params?.simulationId, data: dataAssure});
    return res;
  }
)

export const emprunteurSlice = createSlice({
  name: 'emprunteur',
  initialState,
  reducers: {
        setBaseInformationTarificateur: (state, action) => {
          const newListAssure  = action?.payload?.assures?.map((assure: any, index: number) => {
            const newCurrentAssure: AssureI = {
              assureInfo: assure,
              prets: action?.payload?.prets,
            };
            return newCurrentAssure;
          })

          if (newListAssure?.length > 0) {
            state.listAssure = newListAssure;
          }
        },
        setGarantieByAssureAndPret: (state, action) => {
          const { assureIndex, pretIndex, garanties } = action.payload;
        
          if (assureIndex >= 0 && assureIndex < state.listAssure.length) {
            const assure = state.listAssure[assureIndex];
        
            if (assure && Array.isArray(assure.prets) && pretIndex >= 0 && pretIndex < assure.prets.length) {
              const updatedPrets = assure.prets.map((pret, index) => {
                if (index === pretIndex) {
                  return {
                    ...pret,
                    garanties: garanties
                  };
                }
                return pret;
              });
        
              state.listAssure[assureIndex] = {
                ...assure,
                prets: updatedPrets
              };
            }
          }
        },
        setFranchiseGarantieByAssureAndPret: (state, action) => {
          const { assureIndex, pretIndex, franchiseValue } = action.payload;
        
          if (assureIndex >= 0 && assureIndex < state.listAssure.length) {
            const assure = state.listAssure[assureIndex];
        
            if (assure && Array.isArray(assure.prets) && pretIndex >= 0 && pretIndex < assure.prets.length) {
              // Mettre à jour les garanties pour le prêt spécifique
              const updatedPrets = assure.prets.map((pret, index) => {
                if (index === pretIndex) {
        
                  return {
                    ...pret,
                    franchise: franchiseValue
                  };
                }
                return pret;
              });

              // Mettre à jour l'état avec les garanties modifiées
              state.listAssure[assureIndex] = {
                ...assure,
                prets: updatedPrets
              };
            }
          }
        },
        initFranchiseOnAllAssurePret: (state, action) => {
          const { franchiseValue } = action.payload;
     
          state.listAssure = state.listAssure.map(assure => {
            if (assure && Array.isArray(assure.prets)) {
              const updatedPrets = assure.prets.map(pret => ({
                ...pret,
                franchise: franchiseValue  
              }));
        
              return {
                ...assure,
                prets: updatedPrets
              };
            }
            return assure; 
          });
        },
        setQuotiteByAssureAndPret: (state, action) => {
          const { assureIndex, pretIndex, quotite } = action.payload;
        
          if (assureIndex >= 0 && assureIndex < state.listAssure.length) {
            const assure = state.listAssure[assureIndex];
        
            if (assure && Array.isArray(assure.prets) && pretIndex >= 0 && pretIndex < assure.prets.length) {
              const updatedPrets = assure.prets.map((pret, index) => {
                if (index === pretIndex) {
                  return {
                    ...pret,
                    quotite: quotite
                  };
                }
                return pret;
              });
        
              state.listAssure[assureIndex] = {
                ...assure,
                prets: updatedPrets
              };
            }
          }
        },
        setGarantieObligatoireOnAllAssure: (state, action) => {
          const { garanties } = action.payload;
        
          state.listAssure = state.listAssure.map(assure => {
            if (assure && Array.isArray(assure.prets)) {
              const updatedPrets = assure.prets.map(pret => ({
                ...pret,
                garanties: garanties  
              }));
        
              return {
                ...assure,
                prets: updatedPrets
              };
            }
            return assure; 
          });
        },
        setPrevelementDefault: (state, action) => {
          state.prevelementDefault = action?.payload;
        },
        setTauxComDefault: (state, action) => {
          state.tauxComDefault = action?.payload;
        },
        setTauxComPlusDefault: (state, action) => {
          state.tauxComPlusDefault = action?.payload;
        },
        resetEmprunteurStore: (state) => {
          state = initialState;
        }
  },
  extraReducers: builder => {
    builder
      .addCase(getEmprunteurSimulationAction.pending, state => {
        state.loading = true
      })
      .addCase(getEmprunteurSimulationAction.fulfilled, (state, action) => {
        state.loading = false
        state.listAssure = action?.payload as AssureI[];
      })
      .addCase(getEmprunteurSimulationAction.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(updateAssureEmpAction.pending, state => {
        state.loading = true
      })
      .addCase(updateAssureEmpAction.fulfilled, (state, action) => {
        state.loading = false;
        const payload: any = action?.payload

        const updatedIndex = state.listAssure.findIndex(
            (assure: AssureI) => assure.assureInfo?.id === payload?.id // Remplacez id par le nom de la propriété qui identifie de manière unique l'assure
          );
          if (updatedIndex !== -1) {
            state.listAssure[updatedIndex] = {
              ...state.listAssure[updatedIndex],
              assureInfo: parseDtoToAssure(action.payload),
            };
          }
      })
      .addCase(updateAssureEmpAction.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(createPretAction.pending, state => {
        state.loading = true
      })
      .addCase(createPretAction.fulfilled, (state, action) => {
        state.loading = false;

        const pretToAdd = action.payload;

       
        state.listAssure.forEach(assure => {
          assure.prets.push(pretToAdd);
        });

        //Process la reponse pour l'ajouter dans le state de chaque assure
      })
      .addCase(createPretAction.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(deletePretForAssureAction.pending, state => {
        state.loading = true
      })
      .addCase(deletePretForAssureAction.fulfilled, (state, action) => {
        state.loading = false;


        const assureIdToDelete = state.listAssure.findIndex((assure: any) => assure?.assureInfo?.id === action.payload.assureId);
        if (assureIdToDelete !== -1) {
            // Remplacer l'assuré ayant le prêt supprimé par le currentAssuré
            state.listAssure[assureIdToDelete].prets = state.listAssure[assureIdToDelete].prets.filter(pret => pret.id !== action.payload.pretId);
        }

        //Process la reponse pour l'ajouter dans le state de chaque assure
      })
      .addCase(deletePretForAssureAction.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(updatePretAction.pending, state => {
        state.loading = true
      })
      .addCase(updatePretAction.fulfilled, (state, action) => {
        const payload = action?.payload;

        state.listAssure.forEach(assure => {
          assure.prets = assure.prets.map(pret => {
            if (pret.id === payload?.pret.id) {
              // Copier les garanties du prêt existant dans le prêt mis à jour
              const updatedPret = parseDtoToPret(payload.pret, pret?.garanties, payload.quotite)
              return updatedPret;
            }
            return pret;
          });
        });
        state.loading = false;
      })
      .addCase(updatePretAction.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(createAssureAction.pending, state => {
        state.loading = true
      })
      .addCase(createAssureAction.fulfilled, (state, action) => {
        state.loading = true
      })
      .addCase(createAssureAction.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(getListQuestionnaireAction.pending, state => {
        state.loading = true
      })
      .addCase(getListQuestionnaireAction.fulfilled, (state, action) => {
        state.loading = true
        state.listQuestionnaire = action?.payload
      })
      .addCase(getListQuestionnaireAction.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export const emprunteurActions = emprunteurSlice.actions

export const {
  setTauxComDefault, setTauxComPlusDefault, setBaseInformationTarificateur, resetEmprunteurStore, 
  setGarantieByAssureAndPret, setGarantieObligatoireOnAllAssure, setQuotiteByAssureAndPret,
  setFranchiseGarantieByAssureAndPret, initFranchiseOnAllAssurePret
} = emprunteurActions

export const selectAssureListEmp = (state: RootState) => state.emprunteurSlice?.listAssure

export default emprunteurSlice.reducer
