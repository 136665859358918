import { useAppDispatch, useAppSelector } from "app/hooks";
import { handleNext, selectDossierPrets } from "features/dossierSimuSlice";
import { selectAssureListEmp, setQuotiteByAssureAndPret } from "../emprunteurSlice";
import { Input } from "antd";
import { current } from "@reduxjs/toolkit";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import { useState } from "react";
import { parseAssureEmpPersToDto, parseAssureEmpToDto, parsePretToDto } from "helpers/parseEmprunteur";
import { createPersonnePhysique } from "features/assure/assureAPI";
import { configureGarantiesEmp, createNewAssureEmpruntPret, createNewAssuresEmprunt, createPret, updateEmprunt, updateStatutSimu } from "../emprunteurAPI";
import Loading from "components/Loading/loading";
import { selectEmpruntId } from "features/assure/assureSlice";

const Quotite: React.FC = () => {

  const pret = useAppSelector(selectDossierPrets);
  const assureList = useAppSelector(selectAssureListEmp);
  const empruntId = useAppSelector(selectEmpruntId);
  const dispatch = useAppDispatch();
  const isSmallScreen = useIsSmallScreen(567);
  const [loading, setLoading] = useState<boolean>(false);
  const {simulationId} = useAppSelector(
    (state: any) => state.mainSlice
  );

  console.log("List des assures - >", assureList);

  const handleChangeQuotite = (e: any, indexPret: number, indexAssure: number) => {
    const quotiteData = {
      quotite: e.target.value,
      pretIndex: indexPret,
      assureIndex: indexAssure
    };
    dispatch(setQuotiteByAssureAndPret(quotiteData));
  }

  const isAllQuotiteRemplie = () => {
    let isAllRempli = true;
    
    assureList?.forEach((assure: any) => {
      assure?.prets?.forEach((currentPret: any) => {
        if (!currentPret?.quotite) {
          isAllRempli = false;
        }

        if (isAllRempli === false)
        {
          return isAllRempli;
        }
      })
    })

    return isAllRempli;
  }

  const handleLastStepSubmit = async () => {
    try {
      setLoading(true);
      const pretsIds: string[] = [];
      const assureIds: string[] = []

        const assurePromises = assureList?.map(async (currentAssure: any) => {
            try {
                const personnePhysique = parseAssureEmpPersToDto(currentAssure?.assureInfo);
                await createPersonnePhysique(personnePhysique).then(async (pers: any) => {

                    const dataAssure = parseAssureEmpToDto(pers, currentAssure?.assureInfo);

                    await createNewAssuresEmprunt(dataAssure).then(async (data: any) => {
                        assureIds.push(data?.id)
                    })
                })
            } catch (error) {
                setLoading(false);
                // toast.error("Erreur lors de la création des assurés");
                throw error;
            }
        })

        const pretPromises = pret?.map( async (currentPret: any, indexPret: number) => {
            const pretData = parsePretToDto(currentPret);
            
            await createPret(pretData).then( async (res: any) => {

                await Promise.all(assurePromises);

                assureIds.map(async (currentAssureEmprunt: string, index: number) => {
                    const assureEmprunt = {
                        pretId: res?.id,
                        assureEmpruntId: currentAssureEmprunt,
                        quotite: assureList[index]?.prets[indexPret]?.quotite || 0
                    }
                    await createNewAssureEmpruntPret(assureEmprunt);
                })
                
                pretsIds.push(res?.id);
            })
        })

        await Promise.all(pretPromises).then( async (res: any) => {

          await updateEmprunt({id: empruntId, pretsIds: pretsIds, assuresEmpruntIds: assureIds})

          const garantiesRetenues: any[] = [];
      
          for (let i = 0; i < assureList.length; i++) {
            const currentAssure = assureList[i];
      
              for (let j = 0; j < currentAssure?.prets?.length; j++) {
                const currentPret = currentAssure.prets[j];
      
                for (let k = 0; k < currentPret?.garanties.length; k++) {
      
                  const newGaranties = {
                    garantieRetenueId: currentPret?.garanties[k]?.id,
                    pretId: pretsIds[j],
                    assureEmpruntId: assureIds[i],
                    nombreMoisFranchise: currentPret?.garanties[k]?.franchises?.length > 0 ? currentPret?.franchise : undefined,
                    plafondActiver: currentPret?.garanties[k]?.plafonds.length > 0 && currentPret?.garanties[k]?.plafonds[0]?.duree?.value || undefined
                  }
      
      
                  garantiesRetenues.push(newGaranties);
                }
              }
            }
            const dataConfigure = {
              simulationId: simulationId,
              garantiesRetenuesPrets: garantiesRetenues
            }
      
            await configureGarantiesEmp({simulationId: simulationId, data: dataConfigure}).catch(() => {
              setLoading(false);
              throw ("Erreur");
            })
        })
        await updateStatutSimu({simulationId: simulationId, code: "BT"});
        setLoading(false);
        dispatch(handleNext());
        
    }
    catch {
      setLoading(false);
    }

  }


  return (
    <>
    <div className={`container`}>
        <div className="d-flex flex-direction-col choice-garantie flex-ver-center">
            <h2 className="mg-b30 mg-l10 mg-r10 font-w700 font-22">Quotité</h2>
            <p className="mg-b30 text-dark-light font-w400 font-16 w-85 text-left">Veuillez indiquer la quotité de l'assuré pour chaque prêt.</p>
            <div className="d-flex flex-ver-center flex-hor-center w-100 flex-direction-col">
            {
              pret?.length > 0 && pret?.map((currentPret:any, indexPret: number) => {
                  return (
                    <>
                      <div className="pret-garantie-box mg-b20">
                        <p className="title-pret mg-b20">Prêt n°{indexPret + 1} - {currentPret?.montantPret} €</p>
                        {/* TO-DO Itérer sur les assurés pour ajouter la quotité */}
                        {
                          assureList?.length > 0 && assureList?.map((currentAssure: any, indexAssure: number) => {
                            return (
                              <div className="mg-b10">
                                <p className="text-left font-14 text-dark mg-b10">Quotité de l'assuré {indexAssure + 1}: {currentAssure?.assureInfo?.information?.prenom + " " + currentAssure?.assureInfo?.information?.nom}</p>
                                <Input
                                  addonAfter="%"
                                  className="input"
                                  placeholder="Ex: 2"
                                  value={assureList[indexAssure]?.prets[indexPret]?.quotité}
                                  onChange={(e: any) => handleChangeQuotite(e, indexPret, indexAssure)}
                                  type="number"
                                  min={0}
                                  max={100}
                                />
                              </div> 
                            )
                          })
                        }
                      </div>
                    </>
                  )
              }) 
            }
            </div>
        </div>
            {
              isSmallScreen &&
              <div className="submit-container">
                <button className="button-submit" onClick={() => {handleLastStepSubmit()}} disabled={!isAllQuotiteRemplie()}>
                  Valider et passer à l'étape suviante
                </button>
              </div>
            }
    </div>
    {
      !isSmallScreen &&
      <div className="submit-container">
         <button className="button-submit" onClick={() => {handleLastStepSubmit()}} disabled={!isAllQuotiteRemplie()}>
          Valider et passer à l'étape suviante
        </button>
      </div>
    }
    <Loading loading={loading} isFullPage/>
    </>
  )
}

export default Quotite;