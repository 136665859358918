import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import SelectComponent from "components/Select";
import { Unite } from "helpers/getUnite";
import { useIsSmallScreen } from "hooks/isSmallScreen";
import { IGarantie } from "pages/mainStep/type";
import { useEffect, useState } from "react";
import { setFranchiseGarantieByAssureAndPret } from "../emprunteurSlice";
import { useAppDispatch } from "app/hooks";

type Props = {
    garantie: any,
    franchiseValue: number,
    onCancel: (value: boolean) => void;
    indexAssure: number,
    indexPret: number
}

type conditionGarantie = {
    designation: string;
    duree: {
        id: string;
        unite: number;
        value: number;
    };
    id: string;
    tarification: any;
    valeurMaximale: number;
    valeurMinimale: number;   
    valeurs: number[];   
    valeurDefaut: number;   
    type: number;   
};


const EditFranchiseModal: React.FC<Props> = ({garantie, onCancel, indexAssure, indexPret, franchiseValue}) => {

    const [franchise, setFranchise] = useState<conditionGarantie>();
    const [franchiseValeur, setFranchiseValeur] = useState<number>(franchiseValue);
    const isSmallScreen = useIsSmallScreen(567);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setFranchise(garantie.franchises[0]);
    }, [])


    useEffect(() => {
        if (franchise && franchise?.type === 1) {
          const input = document.querySelector(
            'input[type="range"]'
          ) as HTMLInputElement;
            
          input.style.backgroundSize = Math.ceil(((franchiseValeur - franchise.valeurMinimale) * 100) / (franchise.valeurMaximale - franchise.valeurMinimale)) + "% 100%";
        }   
    }, [franchise]);

    const handleChangeValueFranchise = (direction: string) => {
        if (direction === "down" && franchise && franchiseValeur > franchise.valeurMinimale) {
            setFranchiseValeur((prevFranchise) => {
                if (!prevFranchise) {
                  return prevFranchise; // Si prevFranchise est null ou undefined, ne rien changer
                }
            
               return prevFranchise - 1;
              });
        }
        if (direction === "up" && franchise && franchise.duree.value < franchise.valeurMaximale) {
            setFranchiseValeur((prevFranchise) => {
                if (!prevFranchise) {
                  return prevFranchise; // Si prevFranchise est null ou undefined, ne rien changer
                }
            
                return prevFranchise + 1
              });
        }
    }

    const handleSetValueFranchise = (value: number) => {
      setFranchiseValeur(value);
    }

    const closeModal = () => {
        setFranchise(garantie.franchises[0]);
        onCancel(false);  
    }


    const submitFunction = () => {
        dispatch(setFranchiseGarantieByAssureAndPret({ assureIndex: indexAssure, pretIndex: indexPret, franchiseValue: franchiseValeur}))

        onCancel(false);
    }

    return (
        <div className="d-flex flex-ver-center flex-hor-center flex-direction-col w-100 h-100 overflow-hidden" >
            <div className="d-flex  flex-ver-center flex-hor-between  flex-direction-col w-100 h-100 overflow-hidden"
                style={{padding: `${isSmallScreen ? "0px" : "0px 20px 0px 20px"}`, boxSizing: "border-box"}}
            >
            <div className="d-flex flex-direction-col flex-ver-center flex-hor-center h-100 w-100">
            <h2 className="font-16 lh-19 mg-b18 main-color csf-title">
                Votre franchise
            </h2>
            <p className="font-24 lh-19 mg-b26 csf-valueInput">
                {franchiseValeur} {franchise && Unite[franchise.duree.unite - 1]} <span className="font-16 lh-19">/ franchise</span>
            </p>
            {
                franchise?.type === 2 ?
                <div className="w-50 ">
                    <FormItem shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
                    <Select dropdownStyle={{backgroundColor: "white"}} id="custom-select" onChange={(e) => handleSetValueFranchise(e)} value={franchiseValeur}>
                        {
                           franchise?.valeurs?.map((currenOption: any) => {
                                return (
                                    <Select.Option value={currenOption}>
                                        <span>{`${currenOption} ${Unite[franchise?.duree?.unite - 1]}`}</span>
                                    </Select.Option>
                                )
                            })
                        }
                              
                    </Select>
                    </FormItem>
                </div>
                :
                <div className="csf-inputRange">
                    <div
                    className="actionInputRange mg-r20"
                    onClick={() => handleChangeValueFranchise("down")}
                    >
                        <span>-</span>
                    </div>
                    <input
                        type="range"
                        min={franchise?.valeurMinimale}
                        max={franchise?.valeurMaximale}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleSetValueFranchise(Number(e.target.value));
                        }}
                        value={franchiseValeur}
                    />
                    <div
                    className="actionInputRange mg-l20"
                    onClick={() => handleChangeValueFranchise("up")}
                    >
                        <span>+</span>
                    </div>
                </div>
            }
            </div>

            <div className="button-container">
                <button className="button-modal" onClick={() => closeModal()}>
                    Annuler
                </button>
                <button className="button-modal" onClick={() => submitFunction()}>
                    Valider
                </button>
            </div>
        </div>        
    </div>        
    )
}

export default EditFranchiseModal;