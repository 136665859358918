import { useEffect, useMemo } from "react";
import { useState } from "react";
import arrow from "../../../assets/image/startStep/arrow.svg";
import editNoBackground from "../../../assets/image/infoStepFour/edit.png";
import phone from "../../../assets/image/contratStepTwo/phone.svg";
import classNames from "classnames";
import ContratExplain from "../contratExplain";
import ContratEdit from "../contratEdit";
import { handleNextStep } from "helpers/handleNextStep";

import bigHouseIcon from "assets/image/common/big-house-icon.svg";
import { useNavigate } from "react-router-dom";
import { fetchGarantiesAction, updateGarantiesAction } from "../contratSlice";
import { Garantie, ContantsPrime, garantiesRetenue } from "pages/mainStep/type";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { message } from "antd";
import { downloadDocumentByStoredId } from "pages/mainStep/mainStepAPI";
import defaultGarantie from "assets/image/common/cementery.svg"
import Loading from "components/Loading/loading";

const ContratStepOne = ({ setShowHeader }: any) => {
  const [activeExplain, setActiveExplain] = useState<boolean>(false);
  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  const [explainData, setExplainData] = useState<Garantie>();
  const [garanties, setGaranties] = useState<Garantie[]>([]);
  const [dataEdit, setDataEdit] = useState<{
    data: Garantie;
    index: number;
  } | null>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { simulationId, routeList } = useAppSelector(
    (state) => state.mainSlice
  );
  const { garantiesList, loading } = useAppSelector((state) => state.contratSlice);

  const downloadImg = async (x: any) => {

    let imgUrl;
    if (x && x?.iconeId) {
        const blob = await downloadDocumentByStoredId(x?.iconeId);
        imgUrl = URL.createObjectURL(blob);
    }
    else {
        imgUrl = "";
    }
    return imgUrl;
  }

  async function setGarantiesAsync(garantiesList: any) {
    const updatedGaranties = [];
  
    for (let i = 0; i < garantiesList.length; i++) {
      const x = garantiesList[i];
      const url = await downloadImg(x);
      
      updatedGaranties.push({
        ...x,
        image: url,
        select: x?.isRequired ? true : false,
        ...x?.montantsPrime?.[0],
        montantsPrime: [
          ...(x?.montantsPrime || [])
        ]
      });
    }
  
    updatedGaranties.sort((a, b) => a?.ordreAffichage - b?.ordreAffichage);
  
    // Utilisation de setGaranties avec le résultat
    setGaranties(updatedGaranties);
  }

  useEffect(() => {
    setGarantiesAsync(garantiesList);
  }, [garantiesList, simulationId]);

  useEffect(() => {
    dispatch(fetchGarantiesAction({id: simulationId, franchise: {}}));
  }, [dispatch, simulationId]);

  const isSubmit = useMemo(() => {
    const isRequired = garanties?.filter((x: Garantie) => x?.isRequired);
    const isSelect = garanties?.filter(
      (x: Garantie) => x?.isRequired && x?.select
    );

    if (isRequired?.length === isSelect?.length) {
      return true;
    } else {
      return false;
    }
  }, [garanties]);

  const total = useMemo(() => {
    const isSelect = garanties?.filter((x: Garantie) => x?.select);

    return (
      isSelect?.reduce(
        (total, item) => total + item?.montantPrimeAssurance,
        0
      ) || 0
    );
  }, [garanties]);

  const handleSelect = (index: number) => {
    setGaranties(
      garanties?.map((x: Garantie, indexMap: number) => {
        if (indexMap === index && x?.isRequired === false) {
          return { ...x, select: !x?.select };
        } else {
          return x;
        }
      })
    );
  };

  const handleActiveExplain = (x: Garantie) => {
    setExplainData(x);
    setShowHeader(false);
    setActiveExplain(true);
  };

  const handleActiveEditFranchise = (data: Garantie, index: number) => {
    setDataEdit({ data, index });
    setShowHeader(false);
    setActiveEdit(true);
  };

  const handleToNextStep = async () => {
    const garantiesRetenues: garantiesRetenue[] = [];

    garanties.forEach((garantie) => {
      if (garantie.select) {
        garantiesRetenues.push({
          garantieRetenueId: garantie?.garantieId,
          nombreMoisFranchise: garantie?.nombreMoisFranchise,
          plafondActiver: garantie?.plafondActiver
        });
      }
    });

    const data = {
      simulationId,
      data: {
        simulationId,
        garantiesRetenues: garantiesRetenues
      },
    };


    const res = await dispatch(updateGarantiesAction(data));
    if (res?.payload?.simulationId) {
      navigate(handleNextStep(routeList));
    } else {
      message.error(res?.error?.message);
    }
  };

  const handleChangeData = (data: { data: ContantsPrime; index: number }) => {
    const newGaranties = garanties?.map((x: Garantie, index: number) => {
      if (index === data?.index) {
        return {
          ...x,
          ...data?.data
        };
      } else {
        return x;
      }
    });
    
    setGaranties(newGaranties);

    const franchises = new Map();
    const plafonds = new Map();
    newGaranties.forEach(element => {
      franchises.set(element.garantieId, element.nombreMoisFranchise);
      plafonds.set(element.garantieId, element.plafondActiver);
    });
    
    dispatch(fetchGarantiesAction({id: simulationId, franchises: franchises, plafonds: plafonds}));
  };

  console.log("data", garanties);

  return (
    <div id="contratStepOne">
      <div className="w-100 d-flex flex-direction-col  contratStepOneBody">
        <img
          src={bigHouseIcon}
          alt="bigHouseIcon"
          className="contratStepOneImage"
        ></img>
        <h2>Vos garanties assurances GLI</h2>
        <p className="font-16 lh-25 description">
          Construisez votre contrat d’assurance GLI sur-mesure en sélectionnant
          les garanties dont vous avez besoin.
        </p>
        {garanties?.map((x: Garantie, index: number) => (
          <div
            className={`mg-b25 contratItem ${x?.select ? "activeItem" : ""}`}
            key={index}
          >
            <div
              className="d-flex flex-hor-center flex-ver-center cursor questionIcon"
              onClick={() => handleActiveExplain(x)}
            >
              <p className="font-18">?</p>
            </div>
            <div className="ci-body">
              <div className="w-100 d-flex flex-ver-center flex-hor-start mg-b17 cibr1">
                <img src={x?.image != "" ? x?.image : defaultGarantie} alt="icon" className="mg-r25" />
                <div>
                  <p className="font-14 lh-22">Garantie</p>
                  <span className="font-18 lh-22">{x?.designation}</span>
                </div>
              </div>
              <p className="font-16 lh-20 mg-b19 cibr2">
                {x?.descriptionCourte}
              </p>
              <div className="w-100 d-flex flex-hor-between flex-ver-center cibr4">
                <div className="cibr4c1">
                  <p className="font-20 lh-24 main-color">
                    {x?.montantPrimeAssurance} € TTC
                  </p>
                  <span className="font-13 lh-24">
                    par mois (soit {x?.montantPrimeAssuranceAnnuelle} TTC € / an)
                  </span>
                </div>
                <div className="d-flex flex-ver-center cibr4c2">
                  <p className="mg-r15 font-12 lh-15">
                    {x?.select ? "Couvert" : "Non couvert"}
                  </p>
                  <div
                    className="cursor cibr4c2-switch"
                    onClick={() => handleSelect(index)}
                  >
                    <span className="cibr4c2w-circle"></span>
                  </div>
                </div>
              </div>
            </div>
            {(x?.franchiseMax > 0 || x?.plafondMax > 0 || x?.franchiseValeurs?.length > 0 || x?.plafondValeurs?.length > 0 ) && (
              <div className="w-100 d-flex ci-footer">
                <div
                  className={`pad-20 d-flex flex-ver-center flex-hor-between cifc1 ${
                    x?.montantsPrime?.length > 1 ? "w-50" : "w-100"
                  } ${index % 2 === 0 ? "cifc2" : "cifc1"}`}
                >
                  {(<div className="d-flex flex-direction-col flex-ver-top cifc1c1">
                    <p className="font-10">FRANCHISE</p>
                    <span className="font-15">
                      {x?.nombreMoisFranchise} mois
                    </span>
                  </div>)}

                  {(<div className="d-flex flex-direction-col flex-ver-top cifc1c1">
                    <p className="font-10">PLAFOND</p>
                    <span className="font-15">
                      {x?.plafondActiver } €
                    </span>
                  </div>)}
                  
                  <div
                    className="d-flex flex-hor-center flex-ver-center cursor cifc2c2"
                    onClick={() => handleActiveEditFranchise(x, index)}
                  >
                    <img src={editNoBackground} alt="edit" />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="w-100 d-flex flex-direction-col flex-ver-center contratStepOneFooter">
        <div className="w-100 d-flex flex-hor-between flex-ver-center mg-b7 cttfr1">
          <p className="font-16 lh-19 text-white cttfr1c1">
            Montant total de votre prime
          </p>
          <div className="d-flex flex-direction-col flex-ver-center cttfr1c2">
            <div>
              <p className="font-13 lh-18 text-white">Votre assurance</p>
              <h6 className="font-20 lh-24 text-white">
                {total.toFixed(2)} € TTC <span className="font-14 lh-24">/ mois</span>
              </h6>
            </div>
            <p className="font-14 lh-24 text-white">soit {(total * 12).toFixed(2)}€ TTC /an</p>
          </div>
        </div>
        <button
          // disabled={!select || (select && childrenList?.length > 0 && !data)}
          className="submitButton cttfr2"
          onClick={handleToNextStep}
          disabled={!isSubmit}
        >
          Je valide mes garanties
          <img src={arrow} alt="loading"></img>
        </button>
        <p className="font-13 lh-19 mg-b5 cttfr3">
          Des questions ? Appelez-nous.
        </p>
        <div className="d-flex flex-ver-center mg-b7 cttfr4">
          <img src={phone} alt="phone" className="mg-r8" />
          <p className="mg-r8">0900 752 857</p>
          <h6>du lundi au vendredi de 9h à 17h30</h6>
        </div>
        <p className="font-9 lh-19 cttfr5">
          *prix d’un appel local + frais éventuels de votre opérateur
        </p>
      </div>

      <div className="d-flex flex-direction-col flex-ver-center contratStepOneFixed">
        <div>
          <p className="font-13 lh-18 text-white">Votre assurance</p>
          <h6 className="font-20 lh-24 text-white">
            {total.toFixed(2)} € TTC <span className="font-14 lh-24">/ mois</span>
          </h6>
        </div>
      </div>
      <div
        className={classNames("contratStepOneExplain", {
          activeExplain: activeExplain,
        })}
      >
        <ContratExplain
          close={() => {
            setActiveExplain(false);
            setShowHeader(true);
          }}
          data={explainData}
        ></ContratExplain>
      </div>
      <div
        className={classNames("contratStepOneEdit", {
          activeEdit: activeEdit,
        })}
      >
        <ContratEdit
          close={() => {
            setActiveEdit(false);
            setShowHeader(true);
            setDataEdit(null);
          }}
          data={dataEdit}
          onChangeData={handleChangeData}
        ></ContratEdit>
      </div>
      <Loading loading={loading} isFullPage/>
    </div>
  );
};

export default ContratStepOne;
